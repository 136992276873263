import React from "react";
import Toggle from "react-toggle";
import { useMyContext } from "../../Context/context"
import "react-toggle/style.css"
import { FaMoon } from "react-icons/fa";
import { FiSun } from "react-icons/fi";
export const DarkToggle = () => {
    const { isDarkModeEnabled, updateThemeMode } = useMyContext(false);

    return (
        <div className="row noSpace my-auto mx-4 menuBtnContainer px-3 py-2">
            <FiSun className="my-auto" style={{ color: "#eb9605" }} />
            <Toggle
                className="DarkToggle my-auto mx-2"
                checked={isDarkModeEnabled}
                onChange={event => updateThemeMode(event.target.checked)}
                aria-label="Dark mode"
                icons={false}
            />
            <FaMoon className="my-auto"  style={{ color: "var(--mainWhite)" }}/>

        </div>

    );
};