import React, { useState, useContext } from 'react'

// make a new context
export const MyContext = React.createContext();
export const useMyContext = () => useContext(MyContext);
// create the provider
export const MyProvider = (props) => {
    const [menuOpenState, setMenuOpenState] = useState(false)
    const [title, setTitle] = useState("none")
    const [moduleToShow, setModuleToShow] = useState("none")
    const [isDarkModeEnabled, setDarkMode] = useState(false)
    const [theme, setTheme] = useState("light")
    const updateTitle=(titulo)=>{
        console.log("updating title :", titulo)
        setTitle(titulo);
    }

    const updateThemeMode=(state)=>{
        console.log("is dark mode enable :", state)
        setDarkMode(state);
        if (state===true){
            setTheme("dark")
            console.log("setting to dark")
        }
        else{
            setTheme("light")
            console.log("setting to light")
        }
    }

    const updateModuleToShow=(module)=>{        
        console.log("updating module to show :", module)
        setModuleToShow(module);
        setTitle(module);
    }
    return (
        <MyContext.Provider value={{
            isMenuOpen: menuOpenState,
            title:title,
            currentCount: 3,
            theme,
            isDarkModeEnabled,
            moduleToShow,
            updateTitle,
            updateModuleToShow,
            updateThemeMode,
            toggleMenu: () => setMenuOpenState(!menuOpenState),
            stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
        }}>
            {props.children}
        </MyContext.Provider>
    )
}


