import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useMyContext } from "../../Context/context"
import './navbar.scss'
import "../ModulesList/modules.scss"
import Hamburger from 'hamburger-react'
import L from "./L"
import { DarkToggle } from "./ToogleThemeMode"
import { GoLaw } from "react-icons/go";
// create a navigation component that wraps the burger menu
import { NavLink } from "react-router-dom";
const modules = [
    {
        id: "1",
        icon: <GoLaw  className="my-auto"/>,
        name: "Legislación",       
        link: "/legislacion",
        typeLink:"internal"

    },
    {
        id: "2",
        icon: <GoLaw  className="my-auto"/>,
        //clic: console.log(2),
        name: "Registro Oficial",      
        link: "/registro-oficial",
        typeLink:"internal"
    },
    {
        id: "3",
        icon: <GoLaw   className="my-auto"/>,
        //clic: console.log(2),
        name: "Índice RO",        
        link: "/indice-ro",
        typeLink:"external"
    },
    {
        id: "4",
        icon: <GoLaw  className="my-auto" />,
        //clic: console.log(2),
        name: "Biblioteca LHL",       
        link:"http://lhl.lexis.com.ec",
        typeLink:"external"
    },
    {
        id: "4",
        icon: <GoLaw  className="my-auto" />,
        //clic: console.log(2),
        name: "Herramientas Jurídicas",        
        link:"/herramientas-juridicas",
        typeLink:"internal"
    },
    {
        id: "5",
        icon: <GoLaw  className="my-auto" />,
        //clic: console.log(2),
        name: "LexisNews",        
        link:"/",
        typeLink:"internal"
    }
]

const Navigation = () => {
    const { isMenuOpen, stateChangeHandler } = useMyContext();

    return (
        <Menu
            customBurgerIcon={false}
            isOpen={isMenuOpen}
            onStateChange={(state) => stateChangeHandler(state)}
            left
            className="p-0 m-0"
        >
            

                {modules.map(module =>
  module.typeLink==="internal"?
                    <NavLink to={module.link} className="p-0 m-0">
                        <button key={module.id} className="btn p-0 m-0 menuItem pl-2 pl-md-3"
                            data-tip={module.tooltip}
                            onMouseOver={e => (e.currentTarget.style.color = "var(--mainHover)")}
                            onMouseOut={e => (e.currentTarget.style.color = "var(--mainTheme)")}                          
                        >   <div className="row p-0 m-0 py-2 ">
                                {module.icon}
                                <p className="px-3 pMenu">
                                    {module.name}
                                </p>

                            </div>

                        </button>

                    </NavLink>:
                     <a href={module.link} target="_blank" rel="noopener noreferrer" className="p-0 m-0">
                     <button key={module.id} className="btn p-0 m-0 menuItem pl-2 pl-md-3"
                         data-tip={module.tooltip}
                         onMouseOver={e => (e.currentTarget.style.color = "var(--mainHover)")}
                         onMouseOut={e => (e.currentTarget.style.color = "var(--mainTheme)")}                          
                     >   <div className="row p-0 m-0 py-2 ">
                             {module.icon}
                             <p className="px-3 pMenu">
                                 {module.name}
                             </p>

                         </div>

                     </button>

                 </a>
                    

                )}
        

        </Menu>
    )
}

// default export here
const Navbar = () => {
    const { isMenuOpen, toggleMenu } = useMyContext();
    return (

        <header>
            <Navigation />
            <div className="row p-0 m-0 py-4 px-3 header-site">
            <div className="menuBtnContainer my-auto">
                    <Hamburger toggled={isMenuOpen} toggle={toggleMenu} direction="left" color="var(--mainWhite)" rounded={true} distance="md" size={20}/>
                    </div>
                <div className="col p-0 m-0"></div>

                <div className="row p-0 m-0 my-auto p-2 navbar-right-section">
                
                    <DarkToggle />

                    <div id="single-spa-application:auth" className="my-auto"></div>
                   
                    
                </div>

            </div>
           
        </header>

    )
}

export default Navbar;