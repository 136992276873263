import React from 'react';


import { Helmet } from "react-helmet"
import Navbar from "./MainDrawer/Navbar/Navbar"
import Modules from "./MainDrawer/ModulesList/Modules"
import { useMyContext } from "./Context/context"

function App() {
  const { theme  } = useMyContext();
  
  React.useEffect(()=>{
    console.log(localStorage.getItem("SessionID"))
  },[])
  return (
    <div className="App">

      <Helmet>
        <body className={theme} />
      </Helmet>

     
    
     <React.Fragment>
     <Navbar />
      <Modules />
    
      <div id="single-spa-application:legislacion"></div>
      <div id="single-spa-application:eclex"></div>
      <div id="single-spa-application:herramientas-juridicas"></div>
      </React.Fragment>
      
     
    </div>
  );
}

export default App;
