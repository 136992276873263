import React from 'react'
import ReactTooltip from "react-tooltip";
import FolderIconOneWord from "./FolderIconOneWord"
import FolderIconTwoWords from "./FolderIconTwoWord"


import "./modules.scss"
import {

    Link,

} from "react-router-dom";
const modules = [
    {
        id: "1",
        icon: <FolderIconOneWord texto="Legislación" />,
        name: "Legislación",
        tooltip: "Legislación vigente, derogada, seccional",
        tips: "tips_busqueda_simple.pdf",
        link:"/legislacion",
        typeLink:"internal"


    },
    {
        id: "2",
        icon: <FolderIconTwoWords texto1="Registro" texto2="Oficial" />,
        //clic: console.log(2),
        name: "Registro Oficial",
        tooltip: "Tooltip de registro oficial",
        tips: "tips_busqueda_inteligente.pdf",
        link:"/registro-oficial",
        typeLink:"internal"
    },
    {
        id: "2b",
        icon: <FolderIconTwoWords texto1="Índice" texto2="RO" />,
        //clic: console.log(2),
        name: "Registro Oficial",
        tooltip: "Índice semanal del Registro Oficial",
        tips: "tips_busqueda_inteligente.pdf",
        link:"/indice-ro",
        typeLink:"internal"
    },
    {
        id: "3",
        icon: <FolderIconTwoWords texto1="Biblioteca" texto2="LHL" />,
        //clic: console.log(2),
        name: "Biblioteca LHL",
        tooltip: "Tooltip de Biblioteca LHL",
        tips: "tips_busqueda_inteligente.pdf",
        link:"http://lhl.lexis.com.ec",
        typeLink:"external"
    },  
    {
        id: "4",
        icon: <FolderIconTwoWords texto1="Herramientas" texto2="Jurídicas" />,
        //clic: console.log(2),
        name: "Herramientas Jurídicas",
        tooltip: "Tooltip de herramientas jurídicas",
        tips: "tips_busqueda_inteligente.pdf",
        link:"/herramientas-juridicas",
        typeLink:"internal"
    },    
    
]

export default function Modules() {
  
    return (
        <div className="row p-0 m-0 justify-content-center py-3  mt-4">

            {modules.map(module =>
                <div key={module.id} className="col-6 col-sm-4 col-md-3 col-xl-2 p-0 m-0 text-center">
                    {module.typeLink==="internal"?
                    <Link to={module.link}>
                        <button className="btn p-0 m-0 moduleLogo py-3 w-75"
                            data-tip={module.tooltip}

                        >
                            {module.icon}
                        </button>
                        <ReactTooltip textColor="var(--mainWhite)" backgroundColor="var(--tertiaryBg)" effect='solid' />
                    </Link>
                    :
                    <React.Fragment>
                        <a href={module.link} target="_blank" rel="noopener noreferrer"
                        className="btn p-0 m-0 moduleLogo py-3 w-75"
                            data-tip={module.tooltip}
                          >
                            
                            {module.icon}

                        
                    </a>
                    <ReactTooltip textColor="var(--mainWhite)" backgroundColor="var(--tertiaryBg)" effect='solid' />
                    </React.Fragment>
                    }

                </div>)}
        </div>
    )
}
