
import React from 'react';
import ReactDOM from 'react-dom';
import "core-js/stable";
import "regenerator-runtime/runtime";

import App from './App';
import { MyProvider } from "./Context/context"
import { LifeCycles, registerApplication, start } from "single-spa";


import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router } from 'react-router-dom'; // Import elements for routing


ReactDOM.render(
  <React.StrictMode>
    <MyProvider>
      <Router>
        <App />
      </Router>
     
    </MyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);




registerApplication({
  name: "legislacion",
  app: (): Promise<LifeCycles> => (window as any).System.import("@lexis/legislacion"),
  activeWhen: "/legislacion"
});

registerApplication({
  name: "eclex",
  app: (): Promise<LifeCycles> => (window as any).System.import("@lexis/eclex"),
  activeWhen: "/registro-oficial"
});

registerApplication({
  name: "auth",
  app: (): Promise<LifeCycles> => (window as any).System.import("@lexis/auth"),
  activeWhen: ["/", "/legislacion", "/registro-oficial"]
});

registerApplication({
  name: "herramientas-juridicas",
  app: (): Promise<LifeCycles> => (window as any).System.import("@lexis/herramientas-juridicas"),
  activeWhen: ["/herramientas-juridicas"]
});

registerApplication({
  name: "indice-ro",
  app: (): Promise<LifeCycles> => (window as any).System.import("@lexis/indice-ro"),
  activeWhen: ["/indice-ro"]
});

//start();
//declare var System: any;
 (window as any).System.import("@lexis/styleguide").then(() => {
  // Activate the layout engine once the styleguide CSS is loaded
  //layoutEngine.activate();
  start();
});